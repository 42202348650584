import logo from '@assets/logo.svg';
import { MenuItem } from '@components/header/MenuItem';
import { MenuSubItem } from '@components/header/MenuSubItem';
import { Button } from '@elements/Button';
import { AccountType } from '@models/UserAccount';
import { useLogout } from '@services/auth/use-logout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';
export const Header = () => {
  const { isLoggedIn, user } = useAccountContext();
  const { t } = useTranslation('header');

  const UserMenu = () => {
    const logout = useLogout();
    return (
      <MenuItem title="Account">
        {user?.accountType === AccountType.EMPLOYER ? (
          <MenuSubItem to="/settings" title={t('settings')} />
        ) : (
          <MenuSubItem to="/profile" title={t('profile')} />
        )}
        <MenuSubItem title={t('logout')} onClick={logout} />
      </MenuItem>
    );
  };

  if (import.meta.env.VITE_ENV === 'production') {
    return (
      <div className="fixed z-10 flex h-[80px] w-full flex-[0_1_auto] justify-center rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-0 shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
        <img src={logo} />
      </div>
    );
  }

  return (
    <div className="fixed z-10 flex w-full flex-[0_1_auto] justify-between rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-[24px_40px] shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
      <div className="flex items-center">{isLoggedIn && <UserMenu />}</div>
      <div className="flex gap-4">
        {!isLoggedIn && (
          <>
            <Link to="/register">
              <Button>{t('create_account')}</Button>
            </Link>
            <Link to="/login">
              <Button>{t('login')}</Button>
            </Link>
          </>
        )}
        <Link to="/chat">
          <Button variant="primary" className="p-[10px_64px]">
            {isLoggedIn ? t('talk_with_kora') : t('try_kora')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
