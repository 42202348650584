import Attachment from './attachment.svg?react';
import CheckCircle from './check-circle.svg?react';
import Chevron from './chevron.svg?react';
import ErrorCircle from './error-circle.svg?react';
import Info from './info.svg?react';
import SendArrow from './send-arrow.svg?react';

const ChevronUp = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(180deg)' }} {...props} />
);

export {
  Attachment,
  CheckCircle,
  Chevron as ChevronDown,
  ChevronUp,
  ErrorCircle,
  Info,
  SendArrow
};
