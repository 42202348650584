import React, { ReactElement } from 'react';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | ReactElement | React.ReactNode;
  className?: string;
  variant?: 'info' | 'primary' | 'secondary';
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  [key: string]: any;
}

export const Button = ({
  children,
  variant = 'info',
  className,
  disabled,
  size = 'md',
  ...props
}: IButton) => {
  const baseClass = `rounded-[20px] p-[10px_24px] transition duration-200 ${!disabled ? 'hover:opacity-70' : 'cursor-not-allowed'}`;

  const variantClasses: { [key: string]: string } = {
    info: `border-[#000] border-[1px] ${disabled ? '!bg-[#E0E0E0] border-[#B0B0B0]' : 'bg-transparent'}`,
    primary: `bg-primary text-[#fff] ${disabled ? '!bg-[#C5C5C5] text-[#FFFFFF]' : ''}`,
    secondary: `bg-secondary text-[#fff] ${disabled ? '!bg-[#A3B1F9] text-[#E0E0E0]' : ''}`
  };

  return (
    <button
      className={`${baseClass} ${variantClasses[variant]} text-${size} ${className}`}
      disabled={disabled}
      {...props}
    >
      <p className="transition duration-200">{children}</p>
    </button>
  );
};
