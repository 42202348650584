import { useToast } from '@elements/Toast';
import { Address } from '@models/address';
import { Employer } from '@models/Employer';
import { KoraApi } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const useEmployerPatch = () => {
  const { user, mutate } = useAccountContext<Employer>();
  const { t } = useTranslation('settings');
  const { success, error } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [companyWebsite, setCompanyWebsite] = useState<string | undefined>();
  const [address, setAddress] = useState<Address | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [industry, setIndustry] = useState<string | undefined>();

  useEffect(() => {
    if (user) {
      setEmail(user?.email);
      setFirstName(user?.accountInfo?.firstName);
      setLastName(user?.accountInfo?.lastName);
      setCompanyName(user?.accountInfo?.companyName);
      setCompanyWebsite(user?.accountInfo?.website);
      setAddress(user?.accountInfo?.address ?? undefined);
      setDescription(user?.accountInfo?.description);
      setPhone(user?.accountInfo?.phone);
      setIndustry(user?.accountInfo?.industry);
    }
  }, [user]);

  const patch = async () => {
    setIsSaving(true);
    try {
      const jsonData = {
        email,
        firstName,
        lastName,
        companyName,
        website: companyWebsite,
        address,
        description,
        phone,
        industry
      };

      await KoraApi.patch('/account', jsonData);
      mutate();

      success(t('update_success'));
    } catch (e) {
      error(t('update_failed'));
    }
    setIsSaving(false);
  };

  return useMemo(() => {
    const accountInfo = user?.accountInfo
      ? (user.accountInfo as Employer)
      : null;

    console.log(companyName);
    const canSave =
      email !== user?.email ||
      firstName !== accountInfo?.firstName ||
      lastName !== accountInfo?.lastName ||
      companyName !== accountInfo?.companyName ||
      companyWebsite !== accountInfo?.website ||
      address !== accountInfo?.address ||
      description !== accountInfo?.description ||
      phone !== accountInfo?.phone ||
      industry !== accountInfo?.industry;

    const isComplete =
      (email || user?.email) &&
      (firstName || accountInfo?.firstName) &&
      (lastName || accountInfo?.lastName) &&
      (companyName || accountInfo?.companyName) &&
      (companyWebsite || accountInfo?.website) &&
      (address || accountInfo?.address) &&
      (description || accountInfo?.description) &&
      (phone || accountInfo?.phone) &&
      (industry || accountInfo?.industry);

    return {
      patch,
      canSave: canSave && Boolean(companyName),
      isComplete,
      isSaving,
      setEmail,
      setFirstName,
      setLastName,
      setCompanyName,
      setCompanyWebsite,
      setAddress,
      setPhone,
      setDescription,
      setIndustry,
      email,
      firstName,
      lastName,
      companyName,
      companyWebsite,
      address,
      phone,
      description,
      industry
    };
  }, [
    email,
    setEmail,
    isSaving,
    firstName,
    lastName,
    companyName,
    companyWebsite,
    address,
    phone,
    description,
    industry,
    setFirstName,
    setLastName,
    setCompanyName,
    setCompanyWebsite,
    setAddress,
    setPhone,
    setDescription,
    setIndustry
  ]);
};
