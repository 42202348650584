import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { TextArea } from '@elements/TextArea';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { UserAccount } from '@models/UserAccount';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface SummaryCardProps {
  user?: UserAccount<Candidate>;
}

export const ProfileSummaryCard = ({ user }: SummaryCardProps) => {
  const { t } = useTranslation('profile');
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState<string>();
  const { patch } = useCandidatePatch({ description });

  useEffect(() => {
    const _description = user?.accountInfo
      ? (user.accountInfo as Candidate).description
      : '';
    setDescription(_description);
  }, [user]);

  return (
    <Card>
      <Typography variant="h3" className="mb-6 text-dark">
        {t('summary.title', { name: user?.accountInfo?.firstName })}
      </Typography>
      {description ? (
        <>
          {isEditing ? (
            <>
              <TextArea
                value={description}
                autoGrow
                className="text-sm"
                onChange={e => {
                  setDescription(e.target.value);
                }}
              />
              <Button
                variant="primary"
                size="xs"
                className="mt-3"
                onClick={async () => {
                  await patch();
                  setIsEditing(false);
                }}
              >
                {t('summary.save')}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="caption" className="mb-3">
                {description}
              </Typography>
              <Button
                variant="info"
                onClick={() => setIsEditing(true)}
                size="xs"
                className="p-[6px_16px]"
              >
                {t('summary.edit')}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="caption" className="mb-6 text-secondary">
            {t('summary.complete_intake_description')}
          </Typography>
          <Link to="/chat">
            <Button variant="secondary">{t('complete_intake')}</Button>
          </Link>
        </>
      )}
    </Card>
  );
};
