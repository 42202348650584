import axios, { AxiosError, AxiosRequestConfig } from 'axios';
export const BASE_URL = import.meta.env.VITE_API_URL;
const JWT_TOKEN = 'kora_token';
const JWT_TOKEN_EXP = JWT_TOKEN + '_expiration';
const JWT_REFRESH = 'kora_refresh_token';

export const objectToFormData = (obj: any) => {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const element = obj[key];
      if (element instanceof File) {
        formData.append(key, element, element.name);
      } else if (element instanceof Object) {
        for (const innerKey in element) {
          if (element.hasOwnProperty(innerKey)) {
            if (element[innerKey] !== undefined && element[innerKey] !== null)
              formData.append(`${key}[${innerKey}]`, element[innerKey]);
          }
        }
      } else if (element !== undefined && element !== null) {
        formData.append(key, element);
      }
    }
  }
  return formData;
};

const filterParams = (params = {}) => {
  const asArray = Object.entries(params);
  const filtered = asArray.filter(([_, value]) => {
    return Boolean(value);
  });
  return Object.fromEntries(filtered);
};

export const getJWT = () => {
  const token = localStorage.getItem(JWT_TOKEN);
  if (!token) return '';
  return `Bearer ${token}`;
};

export const clearToken = () => {
  localStorage.removeItem(JWT_TOKEN_EXP);
  localStorage.removeItem(JWT_TOKEN);
  localStorage.removeItem(JWT_REFRESH);
};

export const koraFetcher = async (url: string, params = {}) => {
  await KoraApi.autoRefreshToken();
  const response = await axios
    .get(BASE_URL + `${url}`, {
      params: filterParams(params),
      headers: { Authorization: getJWT() }
    })
    .then(res => res.data);
  return response;
};

export const setCookies = ({
  token,
  exp,
  refresh
}: {
  [key: string]: string;
}) => {
  localStorage.setItem(JWT_TOKEN, token);
  localStorage.setItem(JWT_TOKEN_EXP, exp);
  localStorage.setItem(JWT_REFRESH, refresh);
};

export const KoraApi = {
  autoRefreshToken: async () => {
    const exp = parseInt(localStorage.getItem(JWT_TOKEN_EXP) || '0');
    const now = Date.now();
    if (exp > now) {
      return;
    }
    try {
      const resp = await axios.post(BASE_URL + '/auth/refresh', {
        refresh: localStorage.getItem('kora_refresh_token')
      });
      setCookies({ ...resp.data });
    } catch (error) {
      clearToken();
    }
  },

  get: async function (path: string, params = {}): Promise<any> {
    try {
      const response = await axios.get(BASE_URL + `${path}`, {
        params: filterParams(params),
        headers: { Authorization: getJWT() }
      });
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  },
  /**
   * Send an authenticated post request.
   * @param {string} path The path for the request tot the API
   * @param payload The payload for the put request.
   * @throws error if the http response was not 200/201
   */
  post: async function (
    path: string,
    payload: any,
    download: boolean = false
  ): Promise<any> {
    const extraData: AxiosRequestConfig = {};
    extraData['headers'] = {
      Authorization: getJWT()
    };
    if (download) {
      extraData['responseType'] = 'blob';
    }

    try {
      const response = await axios.post(
        BASE_URL + `${path}`,
        payload,
        extraData
      );
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  },

  /**
   * Send an authenticated put request.
   * @param {string} path The path for the request tot the API
   * @param payload The payload for the put request.
   * @throws error if the http response was not 200/201
   */
  put: async function (
    path: string,
    payload: any,
    options?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const response = await axios.put(BASE_URL + `${path}`, payload, {
        headers: { Authorization: getJWT() },
        ...options
      });
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  },
  patch: async function (
    path: string,
    payload: any,
    options?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const response = await axios.patch(BASE_URL + `${path}`, payload, {
        headers: { Authorization: getJWT() },
        ...options
      });
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  }
};
