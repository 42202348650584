import { ChatBubble } from '@components/chat';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { Input } from '@elements/Input';
import { Attachment, SendArrow } from '@icons/index';
import { useChat } from '@services/chat/use-chat';
import { useEffect, useRef, useState } from 'react';
import { BeatLoader } from 'react-spinners';

export const ChatPage = () => {
  const [message, setMessage] = useState('');
  const messagesEnd = useRef<HTMLDivElement | null>(null);

  const scrollToEnd = () => {
    setTimeout(() => {
      messagesEnd.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const { messages, sendMessage, isAnswering } = useChat(scrollToEnd);

  useEffect(() => {
    scrollToEnd();
  }, [isAnswering]);

  return (
    <Container className="max-h-[calc(100vh-120px)] flex-1 overflow-hidden">
      <div className="flex h-full flex-col">
        <div className="flex flex-1 flex-col gap-4 overflow-auto pb-3">
          {messages.reverse().map(message => (
            <ChatBubble
              key={message.id}
              message={message.message}
              quickActions={message.quickActions}
              isUser={message.senderType === 'USER'}
              isLast={message.id === messages[messages.length - 1].id}
              sendMessage={sendMessage}
            />
          ))}
          {isAnswering && (
            <div className="flex">
              <div className="max-w-[60%] rounded-[20px] bg-[#fff] p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]">
                <BeatLoader color="#171BAF" />
              </div>
            </div>
          )}
          <div style={{ float: 'left', clear: 'both' }} ref={messagesEnd}></div>
        </div>
        <div className="flex-[0_0] py-10">
          <Input
            disabled={isAnswering}
            className="!rounded-[20px] !border-[#000] !bg-transparent !p-6 !pl-[55px] text-sm"
            placeholder="Reply to Kora"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                sendMessage(message);
                setMessage('');
                scrollToEnd();
              }
            }}
            onChange={e => setMessage(e.target.value)}
            value={message}
            startAdornment={
              <Button className="border-none">
                <Attachment />
              </Button>
            }
            endAdornment={
              <Button
                className="border-none"
                onClick={() => {
                  sendMessage(message);
                  setMessage('');
                }}
              >
                <SendArrow />
              </Button>
            }
          />
        </div>
      </div>
    </Container>
  );
};
