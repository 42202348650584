import { QuickAction } from '@models/chatMessage';
import { lazy, Suspense, useEffect, useState } from 'react';
import Markdown from 'react-markdown';

interface ChatBubbleProps {
  message: string;
  quickActions?: QuickAction[];
  isUser: boolean;
  isLast?: boolean;
  sendMessage?: (message: string) => void;
}

const loadComponents = async (
  quickActions: QuickAction[],
  sendMessage?: (message: string) => void
) => {
  const loadedComponents = await Promise.all(
    quickActions.map(async ({ name, data }) => {
      const quickActionName = name
        .split('_')
        .map(
          word =>
            word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
        )
        .join('');
      try {
        const Component = lazy(
          () => import(`./quick-actions/${quickActionName}.tsx`)
        );
        return (
          <Component
            key={quickActionName}
            data={data}
            sendMessage={sendMessage}
          />
        );
      } catch (error) {
        console.error(
          `Component ${quickActionName} could not be loaded`,
          error
        );
        return null;
      }
    })
  );
  return loadedComponents.filter(Boolean);
};

const ChatBubble = ({
  message,
  isUser,
  quickActions,
  isLast,
  sendMessage
}: ChatBubbleProps) => {
  const [components, setComponents] = useState<any[]>([]);

  useEffect(() => {
    if (quickActions) {
      loadComponents(quickActions, sendMessage).then(loadedComponents => {
        setComponents(loadedComponents);
      });
    }
  }, [quickActions]);
  let bubbleClass =
    'max-w-[80%] rounded-[20px]  p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]';
  if (isUser) {
    bubbleClass = `${bubbleClass} bg-[#DBE2FD] text-right`;
  } else {
    bubbleClass = `${bubbleClass} bg-[#fff]`;
  }
  return (
    <>
      <div className={`flex ${isUser && 'justify-end'}`}>
        <div className={bubbleClass}>
          <Markdown className="whitespace-pre-wrap leading-none">
            {message}
          </Markdown>
        </div>
      </div>

      {components.length > 0 && isLast && (
        <Suspense fallback={<></>}>
          <div className="justify-left flex max-w-[60%] gap-4">
            {components.map((component, index) => {
              return (
                <div
                  key={`quick-action-${index}`}
                  className="flex-1 rounded-[20px] bg-[#fff] p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]"
                >
                  {component}
                </div>
              );
            })}
          </div>
        </Suspense>
      )}
    </>
  );
};

export default ChatBubble;
