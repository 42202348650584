import {
  ProfileCTASection,
  ProfilePictureUpload,
  ProgressCircle
} from '@components/profile';
import { ProfileSummaryCard } from '@components/profile/ProfileSummaryCard';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Container } from '@elements/Container';
import { DateInput } from '@elements/DateInput';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { PlaceInput } from '@elements/PlaceInput';
import { Typography } from '@elements/Typography';
import { Address } from '@models/address';
import { Candidate } from '@models/candidate';
import { AccountType, UserAccount } from '@models/UserAccount';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const ProfilePage = () => {
  const { user } = useAccountContext();

  const { t } = useTranslation('profile');
  const navigate = useNavigate();
  const accountInfo = user?.accountInfo
    ? (user.accountInfo as Candidate)
    : null;

  const [email, setEmail] = useState<string | undefined>(user?.email);
  const [firstName, setFirstName] = useState<string | undefined>(
    accountInfo?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(
    accountInfo?.lastName
  );
  const [birthDate, setBirthDate] = useState<Date | undefined>(
    accountInfo?.birthDate ? new Date(accountInfo.birthDate) : undefined
  );
  const [address, setAddress] = useState<Address | undefined>(
    accountInfo?.address ?? undefined
  );

  const [profilePicture, setProfilePicture] = useState<File | undefined>();

  const { patch, canSave, isComplete } = useCandidatePatch({
    email,
    firstName,
    lastName,
    birthDate,
    address,
    profilePicture
  });

  useEffect(() => {
    setEmail(user?.email);
    if (user?.accountType === AccountType.EMPLOYER) {
      navigate('/settings');
    }
  }, [user]);

  useEffect(() => {
    setFirstName(accountInfo?.firstName);
    setLastName(accountInfo?.lastName);
    setBirthDate(
      accountInfo?.birthDate ? new Date(accountInfo.birthDate) : undefined
    );
    setAddress(accountInfo?.address ?? undefined);
  }, [user]);

  const renderForm = () => {
    return (
      <Card>
        <Typography variant="h1" className="mb-6 text-dark">
          {t('welcome')} {firstName}
        </Typography>
        {!isComplete && (
          <Typography variant="caption" className="mb-6 text-secondary">
            {t('fill_missing_data')}
          </Typography>
        )}
        <div className="grid grid-cols-[1fr,1fr] gap-[24px] py-[24px]">
          <Input
            label={t('first_name')}
            variant="compact"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
            }}
          />
          <Input
            label={t('last_name')}
            variant="compact"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
            }}
          />
          <Input
            label={t('email')}
            disabled={user?.authProvider !== 'email'}
            variant="compact"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <DateInput
            variant="compact"
            label={t('date_of_birth')}
            value={birthDate}
            onChange={date => {
              setBirthDate(date);
            }}
          />
          <PlaceInput
            defaultValue={address}
            inputProps={{ variant: 'compact', label: t('city') }}
            onSelect={_address => {
              setAddress(_address);
            }}
          />
        </div>
        <div>
          <Button disabled={!canSave} variant="primary" onClick={patch}>
            {t('save_profile')}
          </Button>
        </div>
      </Card>
    );
  };

  if (!user) return null;

  return (
    <Container>
      <MetaTags title="Profile" />
      <div className="grid grid-cols-[1fr,2fr,1fr] gap-[60px] py-[60px]">
        <div>
          <ProfilePictureUpload
            initialImage={accountInfo?.profilePicture}
            onImageUpload={file => {
              setProfilePicture(file);
            }}
          />
          <ProfileCTASection />
        </div>
        <div className="flex flex-col gap-6">
          {renderForm()}{' '}
          <ProfileSummaryCard user={user as UserAccount<Candidate>} />
        </div>
        <div className="flex flex-col gap-6">
          <ProgressCircle percentage={25} />
        </div>
      </div>
    </Container>
  );
};
