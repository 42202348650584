/** @type {import('tailwindcss').Config} */
export default {
  content: ['./src/**/*.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors: {
        primary: '#171BAF',
        secondary: '#3B55F6',
        success: '#5cb85c',
        error: '#ED4337',
        placeholder: '#444',
        gray: '#c5c5c5',
        dark: '#016'
      },
      fontSize: {
        '4xl': '2.5rem'
      }
    }
  },
  plugins: []
};
