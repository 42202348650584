import { ChatMessage } from '@models/chatMessage';
import { KoraApi } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useSWR from 'swr';

export const useChat = (onMessage?: () => void) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isAnswering, setIsAnswering] = useState(false);
  const {
    data: chatRoom,
    isLoading: isChatRoomLoading,
    error: chatRoomError
  } = useSWR('/chat');
  const {
    messages,
    isLoading: isMessagesLoading,
    mutate: mutateMessages,
    error: messagesError
  } = useChatMessages(chatRoom?.id);

  const sendMessage = async (message: string) => {
    setIsAnswering(true);
    await KoraApi.post(`/chat/${chatRoom?.id}/messages`, { message });
    mutateMessages();
  };

  const initChat = async () => {
    const newSocket = io(import.meta.env.VITE_API_URL);
    newSocket.emit('join', chatRoom.id);
    newSocket.on('connection', socket => {
      socket.join(chatRoom.id);
    });
    newSocket.on('message', (message: any) => {
      mutateMessages();
      try {
        const response = JSON.parse(message);
        console.log(response);
        if (!Boolean(response.hasFollowUp)) setIsAnswering(false);
      } catch (error) {
        console.error('Error parsing message', error);
        setIsAnswering(false);
      }
      onMessage?.();
    });
    setSocket(newSocket);
    const greetingsResponse = await KoraApi.get(
      `/chat/${chatRoom.id}/greetings`
    );
    if (greetingsResponse.data?.waitForGreetings) {
      setIsAnswering(true);
    }
  };

  useEffect(() => {
    if (chatRoom?.id && !socket) {
      initChat();
    }
  }, [chatRoom]);

  const memoized = useMemo(() => {
    onMessage?.();
    return {
      chatRoomId: chatRoom?.id,
      messages,
      sendMessage,
      isAnswering,
      isLoading: isChatRoomLoading || isMessagesLoading,
      error: chatRoomError || messagesError
    };
  }, [
    chatRoom,
    messages,
    isChatRoomLoading,
    isMessagesLoading,
    sendMessage,
    onMessage,
    isAnswering
  ]);

  return memoized;
};

const useChatMessages = (chatRoomId: string) => {
  const { data, ...rest } = useSWR(`/chat/${chatRoomId}/messages`);
  const messages: ChatMessage[] =
    data?.map((message: any) => ChatMessage.fromJson(message)) || [];

  return { messages, ...rest };
};
