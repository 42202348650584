export class Address {
  constructor(
    public street: string | null,
    public city: string,
    public postalCode: string | null,
    public country: string,
    public latitude: number,
    public longitude: number
  ) {}

  static fromJson(json: any): Address {
    return new Address(
      json.street,
      json.city,
      json.postalCode,
      json.country,
      json.latitude,
      json.longitude
    );
  }
}
