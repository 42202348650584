import { useEffect, useRef, useState } from 'react';

export interface ITextArea
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  className?: string;
  autoGrow?: boolean; // New autoGrow prop
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = ({
  label,
  className,
  onChange,
  disabled,
  value: propsValue,
  defaultValue,
  autoGrow, // New prop
  ...props
}: ITextArea) => {
  const [value, setValue] = useState(defaultValue ?? '');
  const textAreaRef = useRef<HTMLTextAreaElement>(null); // Ref for the textarea

  useEffect(() => {
    if (propsValue !== undefined) {
      setValue(propsValue.toString());
    }
  }, [propsValue]);

  useEffect(() => {
    if (autoGrow && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; // Reset height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 5}px`; // Adjust height to fit content
    }
  }, [value, autoGrow]); // Run this effect when the value changes

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <div className="flex flex-col">
      {label && <label className="mb-2">{label}</label>}
      <textarea
        ref={textAreaRef} // Attach the ref
        onChange={handleChange}
        disabled={disabled}
        rows={6}
        data-testid="input-textarea"
        className={`${disabled && '!bg-[#e9e9e9]'} rounded-[8px] border-[1px] border-[#CDCDCD] bg-white p-[10px_12px] font-normal placeholder-[#12121299] ${className}`}
        value={value}
        {...props}
      />
    </div>
  );
};
