import { Address } from './address';

export class Employer {
  constructor(
    public companyName: string,
    public description?: string,
    public industry?: string,
    public website?: string,
    public phone?: string,
    public firstName?: string,
    public lastName?: string,
    public address: Address | null = null
  ) {}

  public static fromJson(json: any): Employer {
    return new Employer(
      json.companyName,
      json.description,
      json.industry,
      json.website,
      json.phone,
      json.firstName,
      json.lastName,
      json.address ? Address.fromJson(json.address) : null
    );
  }
}
