import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface ProgressCircleProps {
  percentage: number;
}

export const ProgressCircle = ({ percentage }: ProgressCircleProps) => {
  const circleStyle = {
    background: `conic-gradient(#4267FF ${percentage * 3.6}deg, #C0CDFB ${percentage * 3.6}deg)`
  };
  const { t } = useTranslation('profile');

  return (
    <div className="relative flex items-center justify-center">
      <div
        className="absolute top-0 flex aspect-square w-full items-center justify-center rounded-full"
        style={circleStyle}
      ></div>
      <div
        className="absolute left-[10px] top-[10px] flex aspect-square w-[calc(100%-20px)] items-center justify-center rounded-full"
        style={{ backgroundColor: '#C0CDFB' }}
      >
        <div className="absolute flex flex-col items-center justify-center">
          <span className="mb-2 text-3xl font-bold text-black">
            {percentage}%
          </span>
          <Link to="/chat">
            <span className="text-normal mt-2 font-bold text-black underline">
              {t('complete_intake')}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
