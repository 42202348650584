import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from './Calendar';
import { Input } from './Input';
import OutsideAlerter from './OutsideAlerter';

function formatDate(date: Date, locale = 'en-US', separator = '-') {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

  //@ts-ignore
  const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);

  const [month, day, year] = formattedDate
    .split('/')
    .map(part => part.padStart(2, '0'));

  if (locale.startsWith('en-US') || locale.startsWith('ja-JP')) {
    // US or similar locales: "MM/dd/YYYY"
    return `${month}${separator}${day}${separator}${year}`;
  } else {
    // Most other locales: "dd/MM/YYYY"
    return `${day}${separator}${month}${separator}${year}`;
  }
}

export interface IDateInput {
  label?: string;
  defaultValue?: Date;
  className?: string;
  value?: Date;
  onChange?: (e: Date) => void;
  variant?: 'default' | 'compact';
}

export const DateInput = ({
  label,
  className,
  onChange,
  value: propsValue,
  defaultValue,
  variant = 'default',
  ...props
}: IDateInput) => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(
    defaultValue ? formatDate(defaultValue, i18n.language) : ''
  );

  useEffect(() => {
    if (propsValue && !value) {
      setValue(formatDate(propsValue, i18n.language));
    }
  }, [propsValue]);

  const [isFocused, setIsFocused] = useState(false);

  const validChars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '/',
    '-'
  ];

  const isValidDate = (date: string) => {
    return (
      moment(date, 'DD-MM-YYYY', true).isValid() ||
      moment(date, 'MM-DD-YYYY', true).isValid() ||
      moment(date, 'DD/MM/YYYY', true).isValid() ||
      moment(date, 'MM/DD/YYYY', true).isValid()
    );
  };

  const stringToDate = (date: string) => {
    date = date.replaceAll('/', '-');
    return moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY'], true).toDate();
  };

  return (
    <OutsideAlerter onClickOutside={() => setIsFocused(false)}>
      <div className="relative">
        <Input
          label={label}
          data-testid="date-input"
          className={className}
          onChange={e => {
            setValue(e.target.value);
            if (isValidDate(e.target.value)) {
              const date = stringToDate(e.target.value);
              setValue(formatDate(date, i18n.language));
              onChange && onChange(date);
            }
          }}
          allowedChars={validChars}
          onFocus={() => setIsFocused(true)}
          value={value}
          variant={variant}
          {...props}
        />
        {isFocused && (
          <div className={`absolute min-w-[300px] transition duration-200`}>
            <Calendar
              data-testid="date-picker"
              locale={i18n.language}
              defaultValue={
                value && isValidDate(value) ? stringToDate(value) : undefined
              }
              onChange={e => {
                const formattedDate = formatDate(e, i18n.language);
                setValue(formattedDate);
                setIsFocused(false);
                onChange && onChange(e);
              }}
            />
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};
