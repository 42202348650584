import { ChatPage } from '@pages/Chat';
import { HomePage } from '@pages/Home';
import { ProfilePage } from '@pages/Profile';
import { SettingsPage } from '@pages/Settings';
import { LoginPage } from '@pages/auth/Login';
import { RegisterPage } from '@pages/auth/Register';
import { VerifyPage } from '@pages/auth/Verify';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes } from 'react-router-dom';

export const KoraRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/linkedin" element={<LinkedInCallback />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/verify/:secret" element={<VerifyPage />} />

      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/settings" element={<SettingsPage />} />

      <Route path="/chat" element={<ChatPage />} />
    </Routes>
  );
};
