import { SettingsOverview } from '@components/settings';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { Tab, Tabs } from '@elements/Tabs';
import { Typography } from '@elements/Typography';
import { useTranslation } from 'react-i18next';

export const SettingsPage = () => {
  const { t } = useTranslation('settings');
  return (
    <Container>
      <MetaTags title="Settings" />
      <div className="box-shadow-[2px_4px_8px_0px_rgba(0,0,0,0.20)] my-[60px] rounded-[4px_20px_20px_20px] bg-white p-6">
        <Typography variant="h1" className="mb-2 text-dark">
          {t('title')}
        </Typography>
        <Tabs>
          <Tab title={t('overview.title')}>
            <SettingsOverview />
          </Tab>
          <Tab title="Notifications">
            <div>Content of Tab 2</div>
          </Tab>
          <Tab title="Members">
            <div>Content of Tab 3</div>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};
