import React, { ReactNode, useState } from 'react';

// Define the prop types for Tab
interface TabProps {
  title: string;
  children: ReactNode;
}

// Tab Component
const Tab: React.FC<TabProps> = ({ children }) => {
  return <div>{children}</div>;
};

// Define the prop types for Tabs
interface TabsProps {
  children: ReactNode;
}

// Tabs Component
const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Extract titles from children
  const titles = React.Children.map(children, child => {
    if (React.isValidElement<TabProps>(child)) {
      return child.props.title;
    }
    return null;
  });

  return (
    <div className="w-full">
      {/* Tab Titles */}
      <div className="flex space-x-4 border-b border-[rgba(0,0,0,0.2)] py-8">
        {titles?.map((title, index) => (
          <div
            key={index}
            data-testid={`tab-${index}`}
            className={`cursor-pointer rounded-[20px] p-[6px_24px] ${
              activeIndex === index
                ? 'bg-primary text-white'
                : 'text-gray-500 border border-black'
            }`}
            onClick={() => setActiveIndex(index)}
          >
            {title}
          </div>
        ))}
      </div>

      {/* Tab Content */}
      <div>
        {React.Children.map(children, (child, index) => {
          if (index === activeIndex && React.isValidElement(child)) {
            return <div className="pt-8">{child}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export { Tab, Tabs };
