import { Button } from '@elements/Button';
import { Checkbox } from '@elements/Checkbox';
import { Typography } from '@elements/Typography';
import { AccountType } from '@models/UserAccount';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormContainer } from '../FormContainer';

interface IAccountTypeSelector {
  onSelect: (type: AccountType) => void;
}
export const AccountTypeSelector = ({ onSelect }: IAccountTypeSelector) => {
  const { t } = useTranslation('register', {
    keyPrefix: 'account_type_selector'
  });
  const { t: tRegister } = useTranslation('register');
  const [selectedType, setSelectedType] = useState<AccountType>();

  return (
    <FormContainer className="w-full max-w-[750px]">
      <Typography variant="h1" className="mb-10 text-center text-xl">
        {t('title')}
      </Typography>
      <div className="mb-10 flex gap-4">
        <div
          className="flex max-w-[50%] items-center gap-4 rounded-[20px] bg-white p-4 hover:cursor-pointer"
          onClick={() => setSelectedType(AccountType.CANDIDATE)}
        >
          <Checkbox checked={selectedType === AccountType.CANDIDATE} />
          <p className="text-xl">{t('candidate')}</p>
        </div>
        <div
          className="flex max-w-[50%] items-center gap-4 rounded-[20px] bg-white p-4 hover:cursor-pointer"
          onClick={() => setSelectedType(AccountType.EMPLOYER)}
        >
          <Checkbox checked={selectedType === AccountType.EMPLOYER} />
          <p className="text-xl">{t('employer')}</p>
        </div>
      </div>
      <div className="text-center">
        {selectedType && (
          <Button
            variant="secondary"
            className="mb-5"
            onClick={() => {
              if (selectedType) {
                onSelect(selectedType);
              }
            }}
          >
            {t(`${selectedType.toLowerCase()}_next_button`)}
          </Button>
        )}
      </div>
      <div className="text-center">
        <Typography
          variant="body1"
          className="mb-1 mt-2 !text-sm !font-normal !text-primary"
        >
          {tRegister('already_have_account')}{' '}
        </Typography>
        <Link to="/login" className="text-sm font-semibold text-[#000000]">
          {tRegister('login')}
        </Link>
      </div>
    </FormContainer>
  );
};
